import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { Headers, Http, RequestOptions } from '@angular/http';
import { Configuracoes } from '../entities/configuracoes';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService extends HttpService  {
  private url = "https://gw.sandbox.pratico.tech" ;
  //private url = "http://localhost:5000" ;

  constructor(public router: Router,
    public http: Http, public toastService: ToastrService) {
      super(http, toastService);
  }

  OnInit(){

  }

  public consultaPedido(pedido, callback)
  {
    return this.http.get(`${this.url}/consultapedido/${pedido}`, { headers: this.getHeaders()} )
                    .pipe(map(resp => resp.json()))
                    .subscribe(
                        resp => {
                            console.log(resp);
                            callback(resp);
                            this.dimissLoading();
                        },
                        error => {
                            console.log(error);
                            this.dimissLoading();
                        }
                    );
  }

  public consultaListaPedido(pedido, callback)
  {
    return this.get(`${this.url}/consultalistapedido${pedido}`, callback);
  }

  public enviaPedido(payment, callback)
  {
    return this.http.post(`${this.url}/enviarpedido`, payment, { headers: this.getHeaders()})
    .pipe(map(resp => resp.json()))
    .subscribe(
      resp => {
          console.log(resp);
          callback(resp);
          this.dimissLoading();
      },
      error => {
          console.log(error);
          this.dimissLoading();
      }
  );

  }

  getHeaders(){
    return new Headers({
      "Content-Type": "application/json",
      'Authorization': `Basic ${Configuracoes.apiKeyGatewayPgto }`
    });
  }
}


