import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http-service';

@Injectable({
  providedIn: 'root'
})
export class MaterialMarketingService extends HttpService {

  constructor(public http: Http, public toastService: ToastrService) {
    super(http, toastService);
  }

  public buscaMaterialMarketinPorGrupo(grupo: number, callback, erorCallBack) {
    this.get(`material-marketing/${grupo}`, callback, erorCallBack);
  }

  public listarGrupos(callback, erorCallBack) {
    this.get("material-marketing/grupos", callback, erorCallBack);
  }

}
