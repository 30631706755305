import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { ConfiguracoesSite } from 'src/app/entities/configuracoesSite';
import { CnpjService } from 'src/app/services/cnpj-service';
import { UsuarioService } from 'src/app/services/usuario-service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
    selector: 'novo-cliente',
    templateUrl: './novo-cliente.component.html',
    styleUrls: ['./novo-cliente.component.css']
})
export class NovoClienteComponent implements OnInit {

    SITE: ConfiguracoesSite;
    public nomeLabel1 = "Razão Social";
    public nomeLabel2 = "Fantasia";
    public documentMask = "00.000.000/0000-00";
    public documentLabel1 = "CNPJ";
    public documentLabel2 = "IE";
    public error: Boolean = false;
    public errorMsg: String;
    public form: FormGroup;
    public ufs: string[] = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG',
        'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];
    public cities: [] = [];
    public tipo_empresa = [{ id: 1, label: "EMPRESA NORMAL" }, { id: 2, label: "EPP - PEQUENO PORTE" }, { id: 3, label: "EPP SIMPLES" }, { id: 4, label: "ME - MICRO EMPRESA" }];
    public tipo_empresaPF = [{ id: 0, label: "CONSUMIDOR FINAL" }];
    public tipo_empresaPJ = [{ id: 1, label: "EMPRESA NORMAL" }, { id: 2, label: "EPP - PEQUENO PORTE" }, { id: 3, label: "EPP SIMPLES" }, { id: 4, label: "ME - MICRO EMPRESA" }];

    public tableInfoDoc;
    public cnpjHistory;

    @Input() registro: boolean = false;

    constructor(private formBuilder: FormBuilder,
        public usuarioService: UsuarioService,
        private cnpjService: CnpjService,
        private router: Router) {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });
        this.SITE = Configuracoes.SITE;
        console.log(this.SITE);
        this.form = this.formBuilder.group({
            nome1: ['', [Validators.required]],
            nome2: [''],
            email: ['', [Validators.required, Validators.email]],
            tipo_empresa: ['', Validators.required],
            tipo: ['CNPJ', Validators.required],
            numDocumento1: ['', [Validators.required, this.validaCNPJ]],
            numDocumento2: ['', Validators.required],
            //endereço
            endereco: ['', Validators.required],
            numero: ['', Validators.required],
            cep: ['', Validators.required],
            bairro: ['', Validators.required],
            uf: ['', Validators.required],
            cidade: ['', Validators.required],
            contato: [''],
            complemento: [''],
            ddd: ['', Validators.required],
            telefone: ['', Validators.required],
            dddWhatsapp: ['', Validators.required],
            telefoneWhatsapp: ['', Validators.required],
            observacao: [''],
            suframa: ['', [Validators.minLength(8), Validators.maxLength(9)]]
        });
    }

    consultaCnpj(event) {
        let cnpj = event.target.value;

        if (this.form.controls.tipo.value != 'CNPJ')
            return;

        if (this.form.controls.numDocumento1.invalid)
            return;

        if (cnpj == this.cnpjHistory)
            return;

        this.cnpjHistory = cnpj;
        this.cnpjService.getCnpjAPIAgilb2b(cnpj, function (resp) {
            resp = JSON.parse(resp);
            this.form.controls.nome1.setValue('');
            this.form.controls.nome2.setValue('');
            this.form.controls.email.setValue('');
            this.form.controls.endereco.setValue('');
            this.form.controls.bairro.setValue('');
            this.form.controls.numero.setValue('');
            this.form.controls.cep.setValue('');
            this.form.controls.complemento.setValue('');
            if (resp.nome) {
                this.form.controls.nome1.setValue(resp.nome);
                if (resp.fantasia) {
                    this.form.controls.nome2.setValue(resp.fantasia);
                }
                // if (resp.email) {
                //     this.form.controls.email.setValue(resp.email);
                // }
                if (resp.logradouro && !Configuracoes.MISC.miscCadCliNoAutEndereco) {
                    this.form.controls.endereco.setValue(resp.logradouro);
                }
                if (resp.bairro && !Configuracoes.MISC.miscCadCliNoAutEndereco) {
                    this.form.controls.bairro.setValue(resp.bairro);
                }
                if (resp.numero && !Configuracoes.MISC.miscCadCliNoAutEndereco) {
                    this.form.controls.numero.setValue(resp.numero);
                }
                if (resp.cep && !Configuracoes.MISC.miscCadCliNoAutEndereco) {
                    this.form.controls.cep.setValue(resp.cep.replace('.', ''));
                }
                if (resp.complemento && !Configuracoes.MISC.miscCadCliNoAutEndereco) {
                    this.form.controls.complemento.setValue(resp.complemento);
                }
                if (resp.uf && resp.municipio) {
                    this.form.controls.uf.setValue(resp.uf);
                    this.searchCities(resp.municipio);
                }
            }
            let situacao = '-';
            let tipo = '-';
            let atividadePrincipal = '-';
            let naturezaJuridica = '-';
            let telefone = '-';
            let atualizacao = resp.ultima_atualizacao ? resp.ultima_atualizacao.substr(0, 10) : '';
            if (resp.situacao) {
                situacao = resp.situacao;
            }
            if (resp.motivo_situacao) {
                situacao += ' - ' + resp.motivo_situacao;
            }
            if (resp.data_situacao) {
                situacao += ' - ' + resp.data_situacao;
            }
            if (resp.tipo) {
                tipo = resp.tipo;
            }
            if (resp.atividade_principal && resp.atividade_principal[0]) {
                atividadePrincipal = resp.atividade_principal[0].code + ' - ' + resp.atividade_principal[0].text;
            }
            if (resp.natureza_juridica) {
                naturezaJuridica = resp.natureza_juridica;
            }
            if (resp.telefone) {
                telefone = resp.telefone;
            }
            let str = `<thead><tr><th colspan="2" scope="col">${cnpj}</th></tr></thead>
            <tr><td>Situação</td><td>${situacao}</td></tr>
            <tr><td>Tipo</td><td>${tipo}</td></tr>
            <tr><td>Atividade Principal</td><td>${atividadePrincipal}</td></tr>
            <tr><td>Natureza Jurídica</td><td>${naturezaJuridica}</td></tr>
            <tr><td>Telefone</td><td>${telefone}</td></tr>
            <tr><td>Atualização</td><td>${atualizacao}</td></tr>`;
            this.tableInfoDoc = str;
        }.bind(this));
    }

    onSubmit() {
        this.error = false;
        console.log(this.form, this.form.value);
        if (!this.form.valid) {
            this.error = true;
            this.errorMsg = "Confira o formulário corretamente!";
            return;
        }
        if (!this.usuarioService.loading)
            this.usuarioService.cadastraCliente(this.registro, this.form.value, this.callback.bind(this), this.callbackError.bind(this));
    }

    callback(resp) {
        if (resp.cliente && resp.cliente == "ok") {
            if (this.registro) {
                Swal.fire('Registro', this.SITE.menssagemAposCadastro ? this.SITE.menssagemAposCadastro : 'Cadastrado com sucesso. Aguarde avaliação da administração!', 'success');
                this.router.navigate(["login"]);
            }
            else {
                Swal.fire('Cliente', this.SITE.menssagemAposCadastro ? this.SITE.menssagemAposCadastro : 'Cadastrado com sucesso!', 'success');
                this.router.navigate(["novo-pedido"]);
            }
        }
    }

    callbackError(error) {
        let obj = JSON.parse(error._body);
        if (obj && obj.msg) {
            this.error = true;
            this.errorMsg = '*** ' + obj.msg;
        }
    }

    formatDocument(event) {
        let value = event.target.value;
        if (value == "CPF") {
            this.tipo_empresa = this.tipo_empresaPF;
            this.form.controls.tipo_empresa.setValue(0);
            this.documentMask = "000.000.000-00";
            this.form.controls.numDocumento1.setValidators([Validators.required, this.validaCPF]);
            this.form.controls.numDocumento2.setValidators([Validators.required]);
            this.documentLabel1 = "CPF";
            this.documentLabel2 = "RG";
            this.nomeLabel1 = "Nome";
            this.nomeLabel2 = "";
            this.form.controls.nome2.clearValidators();
        } else {
            this.tipo_empresa = this.tipo_empresaPJ;
            this.form.controls.tipo_empresa.setValue(0);
            this.documentMask = "00.000.000/0000-00";
            this.form.controls.numDocumento1.setValidators([Validators.required, this.validaCNPJ]);
            this.form.controls.numDocumento2.clearValidators();
            this.documentLabel1 = "CNPJ";
            this.documentLabel2 = "IE";
            this.nomeLabel1 = "Razão Social";
            this.nomeLabel2 = "Fantasia";
            this.form.controls.nome2.setValidators(Validators.required);
        }
        this.form.controls.nome2.updateValueAndValidity();
        this.form.controls.numDocumento2.updateValueAndValidity();
        this.form.controls.numDocumento1.reset();
    }

    searchCities(cidade: string = null) {
        let uf = this.form.value.uf;
        this.usuarioService.cidadesPorUf(uf,
            function (resp) {
                this.cities = resp;
                this.cities.forEach(c => {
                    c.nome = c.nome.toLocaleUpperCase()
                });
                if (cidade) {
                    //this.form.controls.cidade.setValue(cidade);
                    console.log('cidade: ' + cidade);
                    setTimeout(function () {
                        let id = $('#cidade option:contains("' + cidade + '")').val();
                        this.form.controls.cidade.setValue(id);
                    }.bind(this), 1000);
                }
            }.bind(this)
        );
    }

    ngOnInit() {

    }

    validaCPF(c: FormControl) {
        let cpf = c.value;
        let rtn = { validaCPF: { valid: false } };
        if (!cpf || cpf == '') return rtn;
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            return rtn;
        let add = 0;
        let rev = 0;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
            return rtn;
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(10)))
            return rtn;

        return null;
    }

    validaCNPJ(c: FormControl) {
        let cnpj = c.value;
        let rtn = { validaCNPJ: { valid: false } };
        if (!cnpj || cnpj == '')
            return rtn;

        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj.length != 14)
            return rtn;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return rtn;

        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return rtn;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return rtn;

        return null;
    }

}
