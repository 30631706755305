import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LeftSideBarMenuComponent } from "./components/left-side-bar-menu/left-side-bar-menu.component";
import { MaterialMarketingListComponent } from "./components/material-marketing-list/material-marketing-list.component";
import { PageMaterialMarketingComponent } from "./page-material-marketing/page-material-marketing.component";


@NgModule({
  declarations: [
    PageMaterialMarketingComponent,
    LeftSideBarMenuComponent,
    MaterialMarketingListComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule
  ]
})
export class MaterialMarketingModule { }
