import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from './http-service';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { PedidoService } from './pedido-service';
import { interval, Subscription } from 'rxjs';
import { InitProvider } from './init-provider';
import { Configuracoes } from '../entities/configuracoes';
import Swal from 'sweetalert2';
import { ConfiguracoesSite } from '../entities/configuracoesSite';
import { Product } from '../entities/product';
import { Order } from '../entities/order';

@Injectable({
    providedIn: "root"
})
export class ProductService extends HttpService {

    public tabelaSelecionada;
    public filialSelecionada;
    public filtro = {};
    public filtroDescr = [];
    public ordem: string = 'data';
    public direcao: string = 'DESC';
    public selectedOrder = 1;
    public refresh;
    public mostraComFotoModo = false;
    public somenteDisponiveis = false;
    public visualizacaoModo = '4x4';

    constructor(public router: Router,
        public http: Http,
        public toastService: ToastrService,
        public pedidoService: PedidoService,
        public initProvider: InitProvider) {
        super(http, toastService);
    }

    featured(callback) {
        this.selectTabelaFilial();
        let estoque = Configuracoes.SITE.mostraProdutoSemEstoque ? 'false' : 'true';
        let filialID = this.filialSelecionada ? this.filialSelecionada : 'null';
        let preco = Configuracoes.SITE.mostraProdutoSemPreco ? 'false' : 'true';
        let tabelaID = this.tabelaSelecionada ? this.tabelaSelecionada : 'null';

        let find = '(' + estoque + ', ' + filialID + ', ' + preco + ', ' + tabelaID + ')';
        console.log(find);

        this.post('produto/destaques', { find: find }, function (produtosDestaque) {
            callback(produtosDestaque.content);
        }.bind(this));
    }

    public selectTabelaFilial() {
        if (this.pedidoService.pedido && this.pedidoService.pedido.tabela && this.pedidoService.pedido.tabela.id) {
            this.tabelaSelecionada = this.pedidoService.pedido.tabela.id;
        }
        else {
            this.tabelaSelecionada = Configuracoes.SITE.tabelaPadrao;
        }
        if (this.pedidoService.pedido && this.pedidoService.pedido.filial && this.pedidoService.pedido.filial.id) {
            this.filialSelecionada = this.pedidoService.pedido.filial.id;
        }
    }

    public catalogo(page, pageSize, callback) {
        this.selectTabelaFilial();
        let busca = this.filtro['busca'] ? 'array[' + this.filtro['busca'].map(b => "'%" + b.replace(" ", "%") + "%'").join(',') + ']' : 'null';
        let estoque = Configuracoes.SITE.mostraProdutoSemEstoque ? 'false' : 'true';
        let filialID = this.filialSelecionada ? this.filialSelecionada : 'null';
        let preco = Configuracoes.SITE.mostraProdutoSemPreco ? 'false' : 'true';
        let tabelaID = this.tabelaSelecionada ? this.tabelaSelecionada : 'null';
        let tabelaSugeridoID = Configuracoes.SITE.tabelaSugerido ? Configuracoes.SITE.tabelaSugerido : 'null';
        let categoria = this.filtro['categoria'] ? 'array[' + this.filtro['categoria'].join(',') + ']' : 'null';
        let colecao = this.filtro['colecao'] ? 'array[' + this.filtro['colecao'].join(',') + ']' : 'null';
        let departamento = this.filtro['departamento'] ? 'array[' + this.filtro['departamento'].join(',') + ']' : 'null';
        let divisao = this.filtro['divisao'] ? 'array[' + this.filtro['divisao'].join(',') + ']' : 'null';
        let grupo = this.filtro['grupo'] ? 'array[' + this.filtro['grupo'].join(',') + ']' : 'null';
        let marca = this.filtro['marca'] ? 'array[' + this.filtro['marca'].join(',') + ']' : 'null';
        let tipo = this.filtro['tipo'] ? 'array[' + this.filtro['tipo'].join(',') + ']' : 'null';
        let cor = this.filtro['cor'] ? 'array[' + this.filtro['cor'].join(',') + ']' : 'null';
        let tamanho = this.filtro['tamanho'] ? 'array[' + this.filtro['tamanho'].map(t => "'" + t + "'").join(',') + ']' : 'null';
        let produtoComFotoModo = this.mostraComFotoModo ? 'true' : 'false';
        let produtoSomenteDisponiveis = this.somenteDisponiveis ? 'true' : 'false';
        let catalogoInclusivo = Configuracoes.SITE.catalogoInclusivo ? 'false' : 'true';
        let find = '';
        let pedidoId = this.pedidoService.pedido ? this.pedidoService.pedido.id + '' : 'null';

        let estadoImp = "'*'";
        if (this.pedidoService.pedido) {
            pedidoId = this.pedidoService.pedido.id + '';
            if (this.pedidoService.pedido.clientes[0].cliente['cidade'])
                estadoImp = "'" + this.pedidoService.pedido.clientes[0].cliente['cidade']['uf'] + "'";
        }

        switch (this.visualizacaoModo){
            case 'grade':
            case 'gradeClientes':
                find = `catalogo_grade(${page},${pageSize},${busca},${estoque},${filialID},${preco},${tabelaID},${tabelaSugeridoID},${categoria},${colecao},${departamento},${divisao},${grupo},${marca},${tipo},${cor},${tamanho},${this.selectedOrder},${produtoComFotoModo},${produtoSomenteDisponiveis},${catalogoInclusivo},${pedidoId},${estadoImp})`;
                break;

            default:
                find = 'catalogo(' + page + ', ' + pageSize + ', ' + busca + ', ' + estoque + ', ' + filialID + ', ' + preco + ', ' + tabelaID + ', ' + tabelaSugeridoID + ', ' + categoria + ', ' + colecao + ', ' + departamento + ', ' + divisao + ', ' + grupo + ', ' + marca + ', ' + tipo + ', ' + cor + ', ' + tamanho + ', ' + this.selectedOrder + ', ' + produtoComFotoModo + ', ' + produtoSomenteDisponiveis + ', ' + catalogoInclusivo + ',' + estadoImp + ')';
                break;

        }

        // if (this.visualizacaoModo == 'grade') {
        //     find = 'catalogo_grade(' + page + ', ' + pageSize + ', ' + busca + ', ' + estoque + ', ' + filialID + ', ' + preco + ', ' + tabelaID + ', ' + tabelaSugeridoID + ', ' + categoria + ', ' + colecao + ', ' + departamento + ', ' + divisao + ', ' + grupo + ', ' + marca + ', ' + tipo + ', ' + cor + ', ' + tamanho + ', ' + this.selectedOrder + ', ' + produtoComFotoModo + ', ' + produtoSomenteDisponiveis + ', ' + catalogoInclusivo + ', ' + pedidoId + ', ' + estadoImp + ')';
        // } else {
        //     find = 'catalogo(' + page + ', ' + pageSize + ', ' + busca + ', ' + estoque + ', ' + filialID + ', ' + preco + ', ' + tabelaID + ', ' + tabelaSugeridoID + ', ' + categoria + ', ' + colecao + ', ' + departamento + ', ' + divisao + ', ' + grupo + ', ' + marca + ', ' + tipo + ', ' + cor + ', ' + tamanho + ', ' + this.selectedOrder + ', ' + produtoComFotoModo + ', ' + produtoSomenteDisponiveis + ', ' + catalogoInclusivo + ',' + estadoImp + ')';
        // }

        console.log(find);
        this.post('produto/catalogo', { find: find }, function (resp) {
            callback({ produtos: resp.pages, filtros: resp.filtros });
        }.bind(this));
    }

    getPhotos(id, callback) {
        this.get('produto/fotos/' + id, callback);
    }


    getPhotosPromesse(id) {
        return this.getPromisse('produto/fotos/' + id);
    }

    findById(id, callback, callbackError) {
        this.get('produto/' + id, callback, callbackError);
    }

    findByMap(map, callback) {
        let prods = [];
        for (let key of Object.keys(map)) {
            prods.push(key);
        }
        this.post('produto/findByMap', prods, callback);
    }

    getIdQtd(cor, estampa, tamanho): string {
        return cor + '@' + estampa + '@' + tamanho;
    }

    getQuantidade(produto: Product, cor, estampa, tamanho, filial): number {
        let qtd = 0;
        for (var i = 0; i < produto.estoques.length; i++) {
            if (produto.estoques[i]['cor'] == cor
                && produto.estoques[i]['estampa'] == estampa
                && produto.estoques[i]['tamanho'] == tamanho
                && produto.estoques[i]['filial'] == filial) {
                qtd = produto.estoques[i]['quantidade'];
                if (qtd < 0)
                    qtd = 0;
                break;
            }
        }
        return qtd;
    }

    getFormQuantidade(produto: Product, pedido: Order) {
        let formQuantidade = {};
        produto.cores.forEach(cor => {
            produto.estampas.forEach(estampa => {
                produto.tamanhos.forEach(tamanho => {
                    let idQtd = this.getIdQtd(cor['id'], estampa['id'], tamanho['tamanho']);
                    formQuantidade[idQtd] = this.getQuantidade(produto, cor['id'], estampa['id'], tamanho['tamanho'], pedido.filial.id);
                });
            });
        });
        return formQuantidade;
    }

    calculaValorImposto(preco, al_ipi, al_icms, subst_icms, al_icms_orig, al_fcp_st, valor_frete = 0, valor_total_pedido = 0) {
        let impostoCalc = this.calculaImposto(preco, al_ipi, al_icms, subst_icms, al_icms_orig, al_fcp_st, valor_frete, valor_total_pedido);
        return impostoCalc.valor_ipi + impostoCalc.valor_sub + impostoCalc.valor_fcp_st;
    }

    calculaImposto(preco, al_ipi, al_icms, subst_icms, al_icms_orig, al_fcp_st, valor_frete = 0, valor_total_pedido = 0) {
        let impostos = { al_fcp_st: 0, valor_fcp_st: 0, base_ipi: 0, al_ipi: 0, base_sub: 0, al_sub: 0, valor_ipi: 0, valor_sub: 0, base_fcp_st: 0 };

        if (valor_total_pedido !== 0)
            valor_frete = ((preco / valor_total_pedido) * valor_frete);
        let base = ((preco + this.round(valor_frete)));
        let base_st = base * subst_icms;

        if (al_ipi != null && al_ipi != 0) {
            var ipi = base * al_ipi / 100;
            impostos.valor_ipi = this.round(ipi);
            impostos.al_ipi = this.round(al_ipi);
            impostos.base_ipi = this.round(base);
        }
        if (subst_icms != null && subst_icms > 0) {
            impostos.base_sub = this.round(base_st);
            impostos.al_sub = this.round(al_icms_orig);
            impostos.valor_sub = this.round((base_st * al_icms / 100) - ((preco + valor_frete) * al_icms_orig / 100));
        }
        if (al_fcp_st != null && al_fcp_st > 0) {
            impostos.base_fcp_st = this.round(base_st);
            impostos.al_fcp_st = this.round(al_fcp_st);
            impostos.valor_fcp_st = this.round(base_st * al_fcp_st / 100);
        }

        return impostos;
    }

    round(value) {
        return Math.round(100 * value) / 100;
        // return value;
    }

    share(form, callback) {
        this.post('produto/share', { share: JSON.stringify(form) }, callback);
    }

    getShare(hash, callback, callbackError) {
        this.get('principal/getShare/' + hash, callback, callbackError);
    }

    public catalogoShared(hash, page, pageSize, callback) {
        this.selectTabelaFilial();
        let busca = this.filtro['busca'] ? 'array[' + this.filtro['busca'].map(b => "'%" + b.replace(" ", "%") + "%'").join(',') + ']' : 'null';
        let estoque = Configuracoes.SITE.mostraProdutoSemEstoque ? 'false' : 'true';
        let filialID = this.filialSelecionada ? this.filialSelecionada : 'null';
        let preco = Configuracoes.SITE.mostraProdutoSemPreco ? 'false' : 'true';
        let tabelaID = this.tabelaSelecionada ? this.tabelaSelecionada : 'null';
        let tabelaSugeridoID = Configuracoes.SITE.tabelaSugerido ? Configuracoes.SITE.tabelaSugerido : 'null';
        let categoria = this.filtro['categoria'] ? 'array[' + this.filtro['categoria'].join(',') + ']' : 'null';
        let colecao = this.filtro['colecao'] ? 'array[' + this.filtro['colecao'].join(',') + ']' : 'null';
        let departamento = this.filtro['departamento'] ? 'array[' + this.filtro['departamento'].join(',') + ']' : 'null';
        let divisao = this.filtro['divisao'] ? 'array[' + this.filtro['divisao'].join(',') + ']' : 'null';
        let grupo = this.filtro['grupo'] ? 'array[' + this.filtro['grupo'].join(',') + ']' : 'null';
        let marca = this.filtro['marca'] ? 'array[' + this.filtro['marca'].join(',') + ']' : 'null';
        let tipo = this.filtro['tipo'] ? 'array[' + this.filtro['tipo'].join(',') + ']' : 'null';
        let cor = this.filtro['cor'] ? 'array[' + this.filtro['cor'].join(',') + ']' : 'null';
        let tamanho = this.filtro['tamanho'] ? 'array[' + this.filtro['tamanho'].map(t => "'" + t + "'").join(',') + ']' : 'null';
        let produtoComFotoModo = this.mostraComFotoModo ? 'true' : 'false';
        let produtoSomenteDisponiveis = this.somenteDisponiveis ? 'true' : 'false';
        let catalogoInclusivo = Configuracoes.SITE.catalogoInclusivo ? 'false' : 'true';
        let find = '(' + page + ', ' + pageSize + ', ' + busca + ', ' + estoque + ', ' + filialID + ', ' + preco + ', ' + tabelaID + ', ' + tabelaSugeridoID + ', ' + categoria + ', ' + colecao + ', ' + departamento + ', ' + divisao + ', ' + grupo + ', ' + marca + ', ' + tipo + ', ' + cor + ', ' + tamanho + ', ' + this.selectedOrder + ', ' + produtoComFotoModo + ', ' + produtoSomenteDisponiveis + ', ' + catalogoInclusivo + ')';
        this.post('principal/catalogo/' + hash, { find: find }, function (resp) {
            callback({ produtos: resp.pages, filtros: resp.filtros });
        }.bind(this));
    }

    findByIdShared(hash, id, callback, callbackError) {
        this.get('principal/produto/' + hash + '/' + id, callback, callbackError);
    }

}
