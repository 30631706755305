import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/security-service';
import { InitProvider } from 'src/app/services/init-provider';
import { Router } from '@angular/router';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { ObserverPedido, PedidoService } from 'src/app/services/pedido-service';
import { Order } from 'src/app/entities/order';
import { ProductService } from 'src/app/services/product-service';
import { ConfiguracoesSite } from 'src/app/entities/configuracoesSite';
import { ConfiguracoesMisc } from 'src/app/entities/configuracoesMisc';
declare var $: any;

@Component({
  selector: 'gcc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, ObserverPedido {

  SITE: ConfiguracoesSite;
  MISC: ConfiguracoesMisc;

  public isSliderSuperior = true;
  public arrSliderSuperior: Array<String>;
  public intervalSliderSuperior = 2000;
  public fontSizeSliderSuperior = 14;
  public backgroundColorSliderSuperior = '#6b0409';
  public menu;
  public gccMenu;
  public cartTotalItems: Number;
  public API_IMAGENS: String;
  public pedido: Order;
  public busca: string;

  public isComum = false;
  public isGestor = false;
  public isAdmin = false;
  public isLogged = false;

  constructor(public securityService: SecurityService,
    public initProvider: InitProvider,
    public router: Router,
    public pedidoService: PedidoService,
    public productService: ProductService) {
    this.SITE = Configuracoes.SITE;
    this.MISC = Configuracoes.MISC;
    this.pedidoService.registerObserver(this);
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
  }

  private getFilteredMenu(type: string): any[] {
    return JSON.parse(this.SITE.menu).filter((menu) => menu.type.includes(type));
  }

  public getMenu() {
    let menu = new Map();
    if (!this.isLogged) {
      menu.set("/", { label: "Início", link: "/", childs: [] },);
      menu.set("/catalogo", { label: "Catálogo", link: "/catalogo", childs: [] });
      this.getFilteredMenu("not-logged-in").forEach((m) => menu.set(m.link, m));
    } else if (this.isComum) {
      menu.set("/", { label: "Início", link: "/", childs: [] });
      menu.set("/novo-pedido", { label: "Novo Pedido", link: "/novo-pedido", childs: [] });
      menu.set("/pedidos", { label: "Meus Pedidos", link: "/pedidos", childs: [] });
      menu.set("/catalogo", { label: "Catálogo", link: "/catalogo", childs: [] });
      if (this.pedidoService.pedido) {
        menu.set("/checkout", { label: "* Finalizar Pedido", link: "/checkout", childs: [] });
        this.getFilteredMenu("pedido-selecionado").forEach((m) => menu.set(m.link, m));
      }
      this.getFilteredMenu("comum").forEach((m) => menu.set(m.link, m));
    } else if (this.isGestor) {
      menu.set("/", { label: "Início", link: "/", childs: [] });
      menu.set("/novo-pedido", { label: "Novo Pedido", link: "/novo-pedido", childs: [] });
      menu.set("/pedidos", { label: "Meus Pedidos", link: "/pedidos", childs: [] });
      menu.set("/catalogo", { label: "Catálogo", link: "/catalogo", childs: [] });
      if (this.pedidoService.pedido) {
        menu.set("/checkout", { label: "* Finalizar Pedido", link: "/checkout", childs: [] });
        this.getFilteredMenu("pedido-selecionado").forEach((m) => menu.set(m.link, m));
      }
      this.getFilteredMenu("gestor").forEach((m) => menu.set(m.link, m));
    } else if (this.isAdmin) {
      menu.set("/", { label: "Início", link: "/", childs: [] },);
      menu.set("/catalogo", { label: "Catálogo", link: "/catalogo", childs: [] });
      this.getFilteredMenu("admin").forEach((m) => menu.set(m.link, m));
    }

    console.log(this.getFilteredMenu("comum"))
    console.log(Array.from(menu.values()));
    return Array.from(menu.values()).concat(this.SITE.infoPage.filter(i => i.menuPrincipal).map(i => {
      return { label: i.label, link: 'info/' + i.pageId, childs: [] };
    }));
  }

  public refreshMenu() {
    this.menu = this.getMenu();
  }

  public notify(pedido: Order) {
    if (!pedido) {
      delete this.cartTotalItems;
      this.refreshMenu();
      return;
    }
    this.pedido = pedido;
    this.cartTotalItems = pedido.quantidade;
    this.refreshMenu();
  }

  cleanBusca() {
    this.busca = '';
  }

  search() {
    if (this.productService.filtro['busca']) {
      this.productService.filtro['busca'] = this.productService.filtro['busca'].filter(b => b != this.busca);
      this.productService.filtro['busca'].push(this.busca);
    }
    else {
      this.productService.filtro['busca'] = [this.busca];
    }
    if (this.router.url != '/catalogo')
      this.router.navigate(['/catalogo']);
    else
      this.productService.refresh();

    $('#searchModal').modal('hide');
  }

  ngOnInit() {
    $(function () {
      $('body').on('click', '[data-ma-action]', function (e) {
        e.preventDefault();
        let $this = $(this);
        let action = $(this).data('ma-action');
        switch (action) {
          case 'submenu-toggle':
            $this.next().slideToggle(200);
            $this.parent().toggleClass('toggled');
            break;
        }
      });
      $('#searchModal').on('shown.bs.modal', function () {
        $('#searchModalField').trigger('focus')
      });
    });
    this.isLogged = this.securityService.hasToken();
    this.isSliderSuperior = Configuracoes.SITE.isSliderSuperior;
    this.intervalSliderSuperior = Configuracoes.SITE.intervalSliderSuperior;
    this.fontSizeSliderSuperior = Configuracoes.SITE.fontSizeSliderSuperior;
    this.backgroundColorSliderSuperior = Configuracoes.SITE.backgroundColorSliderSuperior;
    this.arrSliderSuperior = Configuracoes.SITE.sliderSuperior;
    this.isComum = this.securityService.isType('COMUM');
    this.isGestor = this.securityService.isType('GESTOR');
    this.isAdmin = this.securityService.isType('ADMINISTRADOR');
    this.menu = this.getMenu();
  }

  openNav() {
    document.getElementById("myCanvasNav").style.width = "100%";
    document.getElementById("myCanvasNav").style.opacity = "0.8";
    this.openOffcanvas();
  }

  openOffcanvas() {
    document.getElementById("myOffcanvas").style.width = "80%";
  }

  closeOffcanvas() {
    document.getElementById("myOffcanvas").style.width = "0%";
    document.getElementById("myCanvasNav").style.width = "0%";
    document.getElementById("myCanvasNav").style.opacity = "0";
  }

  logout() {
    this.securityService.logout();
  }

}