import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './services/loginguard';
import { NoLoginGuard } from './services/no-loginguard';
import { PageHomeComponent } from './pages/page-home/page-home.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageNotfoundComponent } from './pages/page-notfound/page-notfound.component';
import { PageInfoComponent } from './pages/page-info/page-info.component';
import { PageCarrinhoComponent } from './pages/page-carrinho/page-carrinho.component';
import { PagePedidosComponent } from './pages/page-pedidos/page-pedidos.component';
import { PageMeusDadosComponent } from './pages/page-meus-dados/page-meus-dados.component';
import { PageCatalogoComponent } from './pages/page-catalogo/page-catalogo.component';
import { PageProdutoComponent } from './pages/page-produto/page-produto.component';
import { PageCheckoutComponent } from './pages/page-checkout/page-checkout.component';
import { PageNovoPedidoComponent } from './pages/page-novo-pedido/page-novo-pedido.component';
import { PedidoImpressaoComponent } from './pages/pedido-impressao/pedido-impressao.component';
import { PageNovoClienteComponent } from './pages/page-novo-cliente/page-novo-cliente.component';
import { PageAprovaPedidoComponent } from './pages/page-aprova-pedido/page-aprova-pedido.component';
import { DeactivateGuard } from './services/deactivateguard';
import { PageMaterialMarketingComponent } from './pages/material-marketing/page-material-marketing/page-material-marketing.component';

const routes: Routes = [
    { path: 'login', component: PageLoginComponent },
    { path: 'pedidoImpressao', component: PedidoImpressaoComponent },
    { path: 'aprovaPedido', component: PageAprovaPedidoComponent },
    { path: 'notfound', component: PageNotfoundComponent },
    { path: '', component: PageHomeComponent, canActivate: [NoLoginGuard] },
    { path: 'catalogo', component: PageCatalogoComponent, canActivate: [NoLoginGuard] },
    { path: 'info/:pageId', component: PageInfoComponent, canActivate: [NoLoginGuard] },
    { path: 'produto/:id', component: PageProdutoComponent, canActivate: [NoLoginGuard], canDeactivate: [DeactivateGuard] },
    { path: 'novo-pedido', component: PageNovoPedidoComponent, canActivate: [LoginGuard] },
    { path: 'novo-cliente', component: PageNovoClienteComponent, canActivate: [LoginGuard] },
    { path: 'meus-dados', component: PageMeusDadosComponent, canActivate: [LoginGuard] },
    { path: 'pedidos', component: PagePedidosComponent, canActivate: [LoginGuard] },
    { path: 'carrinho', component: PageCarrinhoComponent, canActivate: [LoginGuard] },
    { path: 'checkout', component: PageCheckoutComponent, canActivate: [LoginGuard] },
    { path: 'material-marketing', component: PageMaterialMarketingComponent, canActivate: [LoginGuard] },
    { path: 's', loadChildren: () => import('./pages/page-share/page-share.module').then(c => c.PageShareModule) },
    { path: '**', component: PageNotfoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled",
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
    PageHomeComponent,
    PageMeusDadosComponent,
    PageInfoComponent,
    PageLoginComponent,
    PageCarrinhoComponent,
    PageCheckoutComponent,
    PagePedidosComponent,
    PageCatalogoComponent,
    PageProdutoComponent,
    PageNotfoundComponent,
    PageNovoPedidoComponent,
    PedidoImpressaoComponent,
    PageNovoClienteComponent,
    PageAprovaPedidoComponent
];
