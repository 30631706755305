import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "./http-service";

@Injectable({
    providedIn: "root"
})
export class NewsletterService extends HttpService {

    constructor(
        public http: Http,
        public toastService: ToastrService
    ) {
        super(http, toastService);
    }

    public post(nome: string, email: string, callback) {
        super.post("newsletter", { email: email, nome: nome }, callback);
    }
}