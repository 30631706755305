import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SecurityService } from 'src/app/services/security-service';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { ProductService } from 'src/app/services/product-service';
import { ConfiguracoesSite } from 'src/app/entities/configuracoesSite';
import { LogService } from 'src/app/services/log-service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.css']
})
export class PageLoginComponent {

  SITE: ConfiguracoesSite;

  public form: FormGroup;
  public error: Boolean = false;
  public form2: FormGroup;
  public error2: Boolean = false;
  public API_IMAGENS: String;
  public imageBackground = 1;
  public registroLogin = false;

  constructor(private formBuilder: FormBuilder,
    private securityService: SecurityService,
    public productService: ProductService,
    private logService: LogService) {
    this.SITE = Configuracoes.SITE;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.registroLogin = Configuracoes.MISC.registroLogin;
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });
    this.form2 = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.imageBackground = Math.floor(Math.random() * 5) + 1;
  }

  onSubmit() {
    if (!this.securityService.loading)
      this.securityService.login(this.form.value, this.callbackSuccess.bind(this), this.callbackError.bind(this));
  }

  onRecuperaSenha() {
    if (!this.form2.valid) {
      this.error2 = true;
      return;
    }
    if (!this.securityService.loading)
      this.securityService.recuperaSenha(this.form2.value,
        function (resp) {
          if (resp.msg == "OK") {
            Swal.fire({
              title: 'Recuperando Acesso..',
              html: '<br>Aguarde alguns instantes e <b>verifique sua conta de email!</b>',
              icon: 'success',
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              timer: 10000,
              onBeforeOpen: () => {
                Swal.showLoading()
              },
              onClose: () => {
                window.location.href = '/';
              },
              padding: '3em',
              backdrop: `
              rgba(0,0,123,0.4)
              url("/assets/nyan-cat.gif")
              left top
              no-repeat
            `
            });
          }
        }.bind(this),
        function (error) {
          Swal.fire({
            title: 'Recupera Acesso',
            html: '<br>Não foi possível recuperar seu acesso, aguarde alguns instantes para nova tentativa...',
            icon: 'error',
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 10000
          });
        }.bind(this));
  }

  callbackSuccess(resp) {
    this.logService.userLogin(resp);
    if (this.SITE.appendHeadAfterLogin)
      $('head').append(this.SITE.appendHeadAfterLogin);
  }

  callbackError(e) {
    this.error = true;
  }

}