import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Configuracoes } from '../entities/configuracoes';
import { SecurityService } from './security-service';

@Injectable({
    providedIn: "root"
})
export class NoLoginGuard implements CanActivate {

    constructor(private securityService: SecurityService,
        private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (Configuracoes.SITE.painelSemLoginAtivo)
            return true;
        if (this.securityService.hasToken())
            return true;
        this.router.navigate(['login']);
        return false;
    }

}