import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { Configuracoes } from '../entities/configuracoes';
import { Usuario } from '../entities/usuario';
declare var $:any;

@Injectable({
    providedIn: 'root'
})
export class InitProvider {

    public usuario:Usuario;
    public filiais:any = [];
    
    constructor(public http:Http) {}

    async load() {
        return await new Promise((resolve, reject) => {
            this.http.get("appconfig-v2.json", this.getHttpOptions())
            .pipe(map( config => config.json()))
            .subscribe(config => {
                Configuracoes.patchAppConfig(config);
                //console.log('configuracoes', Configuracoes);
                resolve( 
                    new Promise((resolve, reject) => {
                        this.http.get(Configuracoes.API + "principal", this.getHttpOptions())
                        .pipe(map( resp => resp.json()))
                        .subscribe(
                        async resp => {
                            //console.log("principal", resp);
                            Configuracoes.SITE = resp.configuracao;
                            this.filiais = resp.filiais.filter(f => f.ativo);
                            if(this.hasToken()){
                                resolve(
                                    new Promise((resolve, reject) => {
                                        this.http.get(Configuracoes.API + "usuario", this.getHttpOptionsLogin())
                                        .pipe(map( resp => resp.json()))
                                        .subscribe(
                                        resp => {
                                            //console.log('loginInitProvider usuario', resp);
                                            this.usuario = resp;
                                            if(Configuracoes.SITE.appendHeadAfterLogin){
                                                $('head').append(Configuracoes.SITE.appendHeadAfterLogin);
                                            }
                                            resolve(true);
                                        },
                                        error => {
                                            //console.log('token vencido...');
                                            window.localStorage.removeItem("token");
                                            window.localStorage.removeItem("tipoUsuario");
                                            window.location.href = '/login';
                                        });
                                    })
                                );
                            }
                            else{
                                resolve(true);
                            }
                        });
                    })
                );

            });
        });
    }

    hasToken() {
        return window.localStorage.getItem('token') != null;
    }

    getToken() {
        return window.localStorage.getItem("token");
    }

    getHttpOptionsLogin() {
        var header = new Headers({
          "Content-Type": "application/json",
          "Branch": Configuracoes.API_BRANCH
        });
        if (this.hasToken()) {
          header.set("Authorization", "Bearer " + this.getToken());
        }
        return new RequestOptions({
          headers: header
        });
    }

    getHttpOptions() {
        var header = new Headers({
          "Content-Type": "application/json",
          "Branch": Configuracoes.API_BRANCH
        });
        return new RequestOptions({
          headers: header
        });
    }

    /*getHttpOptions() {
        var header = new Headers({
          "Content-Type": "application/json"
        });
        return new RequestOptions({
          headers: header
        });
    }*/

}