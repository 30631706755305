import { Component, OnInit } from '@angular/core';
import { SliderPrincipalValue } from 'src/app/components/components';
import { ProductService } from 'src/app/services/product-service';
import { Product } from 'src/app/entities/product';
import { Configuracoes } from 'src/app/entities/configuracoes';
declare var $: any;

@Component({
  selector: 'page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css']
})
export class PageHomeComponent implements OnInit {

  public fadeSliderPrincipal = true;
  public intervalSliderPrincipal = 4000;
  public ballIndicatorSliderPrincipal = true;
  public mousePauseSliderPrincipal = false;
  public arrSliderPrincipal: Array<SliderPrincipalValue>;

  public titleSliderProdutos;
  public dotsSliderProdutos = true;
  public infiniteSliderProdutos = true;
  public intervalSliderProdutos = 4000;
  public productsFeatured: Product[];
  public newsletterActive;

  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.featured(function (resp) {
      this.productsFeatured = resp;
    }.bind(this));
    this.newsletterActive = Configuracoes.SITE.newsletterActive;
    this.fadeSliderPrincipal = Configuracoes.SITE.fadeSliderPrincipal;
    this.intervalSliderPrincipal = Configuracoes.SITE.intervalSliderPrincipal;
    this.ballIndicatorSliderPrincipal = Configuracoes.SITE.ballIndicatorSliderPrincipal;
    this.mousePauseSliderPrincipal = Configuracoes.SITE.mousePauseSliderPrincipal;
    this.arrSliderPrincipal = Configuracoes.SITE.sliderPrincipal;
    this.titleSliderProdutos = Configuracoes.SITE.titleSliderProdutos;
    this.dotsSliderProdutos = Configuracoes.SITE.dotsSliderProdutos;
    this.infiniteSliderProdutos = Configuracoes.SITE.infiniteSliderProdutos;
    this.intervalSliderProdutos = Configuracoes.SITE.intervalSliderProdutos;
  }

}