import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { UsuarioService } from 'src/app/services/usuario-service';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.css']
})
export class PageInfoComponent {
  public text: String;

  constructor(private route: ActivatedRoute,
    private usuarioService: UsuarioService) {
    this.route.params.subscribe(params => {
      if (params['pageId']) {
        let temp = Configuracoes.SITE.infoPage.filter(i => i['pageId'] == params['pageId'])[0];
        this.usuarioService.getInfoPageHtml(temp['id'], function (resp) {
          this.text = resp.html;
        }.bind(this));
      }
    });
  }
}