import { Component, Input, OnInit } from '@angular/core';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { DownloadImagesService } from 'src/app/services/download-images.service';
import { MaterialMarketing } from '../../model/material-marketing';
import { MaterialMarketingGrupo } from '../../model/material-marketing-grupo';

@Component({
  selector: 'app-material-marketing-list',
  templateUrl: './material-marketing-list.component.html',
  styleUrls: ['./material-marketing-list.component.css']
})
export class MaterialMarketingListComponent implements OnInit {

  @Input() public itens: MaterialMarketing[];
  @Input() public grupo: MaterialMarketingGrupo;
  public itemHeight = 200;
  public itemDescrHeight = 40;
  public itemDescrLen = 44;
  public itemDescrFontSize = 0.9;
  public itemIndispFontSize = 0.8;
  public API_IMAGENS: string;

  constructor(private downloadImageService: DownloadImagesService) {
    this.API_IMAGENS = Configuracoes.API_IMAGENS + "material-marketing";
  }

  ngOnInit(): void {
  }

  public downloadFile(file) {
    if (this.downloadImageService.loading)
      return;

    this.downloadImageService.clear();
    this.downloadImageService.append(
      Configuracoes.API_IMAGENS + "material-marketing/files/" + file
    );
    this.downloadImageService.download(file);
  }

}
