import { Component,HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product-service';
import { Product } from 'src/app/entities/product';
import { SecurityService } from 'src/app/services/security-service';
import { PedidoService } from 'src/app/services/pedido-service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { OrderItem } from 'src/app/entities/orderItem';
import { DeactvateGuardComponent } from 'src/app/entities/deactvateguardcomponent';
import { Observable, of, Subject } from 'rxjs';
import { Order } from 'src/app/entities/order';
import { ConfiguracoesSite } from 'src/app/entities/configuracoesSite';
import { CurrencyPipe } from '@angular/common';
import { InitProvider } from 'src/app/services/init-provider';
declare var $: any;
declare var ch: any;

@Component({
  selector: 'app-page-produto',
  templateUrl: './page-produto.component.html',
  styleUrls: ['./page-produto.component.css']
})
export class PageProdutoComponent implements DeactvateGuardComponent, OnDestroy {
  multiplo = 1;
  SITE: ConfiguracoesSite;
  modoVisualizacaoPageProduto: number;
  public descr1WhiteSpace = true;
  public mostrarEspecificacao = true;
  public descr2WhiteSpace = true;
  public descr2MarginTop = 20;
  public descr1Limit = 400;
  public descr2Limit = 400;
  private _imagens;
  public descontoPercent: any = 0;
  public descontoPorItem: number;
  // private _especificacoes;
  set imagens(arr) {
    if (arr) {
      this._imagens = arr;
      this.init();
    }
  }

  get imagens() {
    return this._imagens;
  }


  
  @HostListener('click')
  onClick() {
    let compativelCom = $('nav-compativel-com')
    if (compativelCom){
    console.log("MOSTRA ESPECIFICAÇÃO ", this.mostrarEspecificacao)
    console.log("ABA ", compativelCom)
    this.mostrarEspecificacao = true;
    }else{
      console.log("MOSTRA ESPECIFICAÇÃO ", this.mostrarEspecificacao)
      console.log("ABA ", compativelCom)
      this.mostrarEspecificacao = false;
    }
}

 
  public product: Product = { imagens: [], cores: [], estampas: [], especificacoes: [], tamanhos: [], estoques: [], precos: [], quantidadeMaximaDisponivelGrade: 0 };
  public cores: [];
  public estampas: [];
  public tamanhos: [];
  public clientes: any[];
  public especificacoes:[];
  public corSelecionada;
  private items = [];
  public form: FormGroup = new FormGroup({});
  public formQuantidade = {};
  public formQuantidadeDigitada = {};
  public error: Boolean = false;
  public errorMsg: String;
  public API_IMAGENS: String;
  public canDeactivateObs: Subject<boolean> = new Subject();
  public pedido: Order;
  public tabelaPadrao;
  public filialSelecionada;
  public ufsImpostosMap = { "*": 0 };
  public ufSelected = "*";
  public mostraLabelImposto = false;
  public precosDivergentes = {};
  private currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
  public mostraPreco = false;
  public valorTotalImposto = 0;
  public valorTotal = 0;
  public preco;
  public clienteSelecionado;

  filiais: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    public pedidoService: PedidoService,
    public securityService: SecurityService,
    private initProvider: InitProvider
  ) {
    this.SITE = Configuracoes.SITE;
    this.modoVisualizacaoPageProduto = Configuracoes.modoVisualizacaoPageProduto;
    this.pedido = this.pedidoService.pedido;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.filiais = initProvider.filiais;
    console.log(this.filiais);

    if (!this.securityService.hasToken() && !this.SITE.mostraPrecoLoginAtivo)
      this.mostraPreco = true;
    if (this.securityService.hasToken() && (!this.SITE.mostraPrecoPedidoSelecionado || this.pedido))
      this.mostraPreco = true;


    this.tabelaPadrao = this.SITE.tabelaPadrao;
    let style = JSON.parse(this.SITE.produtoStyle);
    if (style) {
      if (style['descr1WhiteSpace'] !== undefined) {
        this.descr1WhiteSpace = style['descr1WhiteSpace'];
      }
      if (style['descr2WhiteSpace'] !== undefined) {
        this.descr2WhiteSpace = style['descr2WhiteSpace'];
      }
      if (style['descr2MarginTop'] !== undefined) {
        this.descr2MarginTop = style['descr2MarginTop'];
      }
      if (style['descr1Limit'] !== undefined) {
        this.descr1Limit = style['descr1Limit'];
      }
      if (style['descr2Limit'] !== undefined) {
        this.descr2Limit = style['descr2Limit'];
      }
    }

    this.route.params.subscribe(params => {
      this.productService.findById(params['id'],
        this.callback.bind(this),
        function () {
          this.router.navigate(['/notfound']);
        }.bind(this)
      );
    });

    this.descontoPorItem = Configuracoes.descontoPorItem;
  }

  callback(p) {
    this.product = p;
    this.cores = p.cores;
    this.estampas = p.estampas;
    this.tamanhos = p.tamanhos;
    this.multiplo = p.multiplo;
    this.especificacoes = p.especificacoes;
  

    if (this.pedido && this.pedido.tabela && this.pedido.filial && this.product.ativo && this.product.tabelasPrecos[this.pedido.tabela.id] && this.product.tabelasPrecos[this.pedido.tabela.id].preco > 0) {
      let clientes: any = [];
      let countUFs = 0;
      this.pedidoService.pedido.clientes.forEach(pc => {
        clientes.push(pc.cliente);
        if (pc.cliente.cidade && pc.cliente.cidade.uf) {
          if (this.ufsImpostosMap[pc.cliente.cidade.uf] == undefined) {
            countUFs++;
          }
          this.ufSelected = pc.cliente.cidade.uf;
          this.ufsImpostosMap[pc.cliente.cidade.uf] = 0;
        }
      });
      this.clientes = clientes;

      this.clienteSelecionado = clientes[0];

      if (countUFs > 1) {
        this.mostraLabelImposto = true;
      }
    }

    if (this.pedidoService.pedido && this.pedidoService.pedido.filial && this.pedidoService.pedido.filial.id) {
      this.filialSelecionada = this.pedidoService.pedido.filial.id;
    }

    if (this.descontoPorItem == 1 && this.pedidoService.pedido && this.pedidoService.pedido.itens && this.pedidoService.pedido.itens.length > 0)
        this.descontoPercent = this.pedidoService.pedido.itens[0].descontoPercent.toString().replace(".", ",");

    let imagens = [];
    if (this.cores.length) {
      for (var i = 0; i < this.cores.length; i++) {
        let img = { id: 'assets/no-product-image.jpg' + this.cores[i] + this.product.id, value: 'assets/no-product-image.jpg' };
        let temEstoque = false;
        if (this.filialSelecionada) {
          for (let x = 0; x < this.product.estoques.length; x++) {
            if (this.product.estoques[x]['cor'] == this.cores[i]['id'] && this.product.estoques[x]['filial'] == this.filialSelecionada && this.product.estoques[x]['quantidade'] > 0) {
              temEstoque = true;
              break;
            }
          }
        }
        else {
          for (let x = 0; x < this.product.estoques.length; x++) {
            if (this.product.estoques[x]['cor'] == this.cores[i]['id'] && this.product.estoques[x]['quantidade'] > 0) {
              temEstoque = true;
              break;
            }
          }
        }

        let imagensSrc = this.product.imagens.filter(imagem => { return imagem.cor == this.cores[i]['id'] }).map((imagem, index) => {
          return {
            id: imagem.indice + imagem.cor + this.product.id,
            value: this.API_IMAGENS + 'produtos/' + imagem.nome,
            classe: index == 0 ? "active" : "",
          }
        });

        imagens.push({ cor: this.cores[i]['id'], descricao: this.cores[i]['descricao'], src: imagensSrc.length ? imagensSrc[0] : img, sources: imagensSrc, estoque: temEstoque });
      }
      imagens.sort((a, b) => {
        if (b.estoque && !a.estoque)
          return 1;
        else if (!b.estoque && a.estoque)
          return -1;
        else
          return 0;
      });
      console.log(imagens);
      this.corSelecionada = imagens[0];
      this.imagens = imagens;
    }

    if (this.pedido && this.pedido.tabela && this.pedido.filial && this.product.ativo && this.product.tabelasPrecos && this.product.tabelasPrecos[this.pedido.tabela.id] && this.product.tabelasPrecos[this.pedido.tabela.id].preco > 0) {
      let preco = this.product.tabelasPrecos[this.pedido.tabela.id].preco;
      this.preco = preco;
      if (this.product.tabelasPrecos[this.pedido.tabela.id].precoPromocional) {
        preco = this.product.tabelasPrecos[this.pedido.tabela.id].precoPromocional;
      }
      this.product.impostos.forEach(imp => {
        for (let uf of Object.keys(this.ufsImpostosMap)) {
          if (uf == imp['estado']) {
            console.log('imp', imp);
            let impostoCalc = this.productService.calculaImposto(
              preco,
              imp['ipi'],
              imp['alIcms'],
              imp['substIcms'],
              imp['alIcmsOrigem'],
              imp['fcpSt']
            );
            console.log('impCalc', impostoCalc);
            this.ufsImpostosMap[uf] = [
              { tipo: 'Fcp-St', valor: impostoCalc.valor_fcp_st },
              { tipo: 'Ipi', valor: impostoCalc.valor_ipi },
              { tipo: 'ST', valor: impostoCalc.valor_sub }
            ];

            this.valorTotalImposto = this.productService.calculaValorImposto(preco, imp['ipi'], imp['alIcms'], imp['substIcms'], imp['alIcmsOrigem'], imp['fcpSt'])

            if (!this.SITE.mostraImpostoDetalhado) {
              this.preco = preco + this.valorTotalImposto;
            }

            this.valorTotal = preco + this.valorTotalImposto;
          }
        }
      });
      for (let uf of Object.keys(this.ufsImpostosMap)) {
        if (this.ufsImpostosMap[uf]['total'] <= 0) {
          delete this.ufsImpostosMap[uf];
        }
      }
      console.log('impostos', this.ufsImpostosMap);

      //Montando a grade
      this.cores.forEach(cor => {
        this.estampas.forEach(estampa => {
          this.tamanhos.forEach(tamanho => {
            this.clientes.forEach(cliente => {
              let id = this.getIdField(cor['id'], estampa['id'], tamanho['tamanho'], cliente['id']);
              let fc = new FormControl({ value: '', disabled: false }, [Validators.min(0)]);
              if (this.pedido.tipo.validaEstoque) {
                let quantidade = this.productService.getQuantidade(this.product, cor['id'], estampa['id'], tamanho['tamanho'], this.pedido.filial.id);
                fc = new FormControl({ value: '', disabled: false }, [Validators.min(0), Validators.max(quantidade)]);
              }
              this.form.addControl(id, fc);
            });
            if (this.pedido.tipo.precoDivergente) {
              let idSimple = this.getIdFieldSimple(cor['id'], estampa['id'], tamanho['tamanho']);
              let precoMin = this.getPrecoSimple(cor['id'], estampa['id'], tamanho['tamanho'], this.SITE.precoDivergenteTabelaMin);
              let precoIdeal = this.getPrecoSimple(cor['id'], estampa['id'], tamanho['tamanho'], this.SITE.precoDivergenteTabelaIdeal);
              this.precosDivergentes['min' + idSimple] = this.currencyPipe.transform(precoMin, '', '');
              this.precosDivergentes['ideal' + idSimple] = this.currencyPipe.transform(precoIdeal, '', '');
              let fc = new FormControl(this.currencyPipe.transform(precoIdeal, '', ''), [Validators.min(0)]);
              this.form.addControl('precoDiv@' + idSimple, fc);
            }
          });
        });
      });

      this.formQuantidade = this.productService.getFormQuantidade(this.product, this.pedido);

      this.pedidoService.pedido.itens.forEach(e => {
        if (e['produto'] == this.product.id) {
          let id = this.getIdField(e['cor'], e['estampa'], e['tamanho'], e['cliente']);
          this.form.get(id).setValue(e['quantidade']);
          if (this.pedido.tipo.precoDivergente) {
            this.form.get('precoDiv@' + e['cor'] + '@' + e['estampa'] + '@' + e['tamanho']).setValue(this.currencyPipe.transform(e['preco'], '', ''));
          }
        }
      });
    }
  }

  public SetCorSelecionada(cor) {
    for (var i = 0; i < this.imagens.length; i++) {
      if (cor.id == this.imagens[i].cor) {
        this.corSelecionada = this.imagens[i];
        break;
      }
    }
  }

  getIdField(cor, estampa, tamanho, cliente): string {
    return cor + '@' + estampa + '@' + tamanho + '@' + cliente;
  }

  getIdFieldSimple(cor, estampa, tamanho): string {
    return cor + '@' + estampa + '@' + tamanho;
  }

  getPrecoSimple(cor, est, tam, tab) {
    let preco = 0;
    this.product.precos.forEach(p => {
      if (cor == p['cor'] && est == p['estampa'] && tam == p['tamanho'] && tab == p['tabela']) {
        if (p['precoPromocional'] > 0) {
          preco = p['precoPromocional'];
        }
        else {
          preco = p['preco'];
        }
      }
    });
    return preco;
  }

  getEstoqueSimpleString(cor, est, tam, fil) {
    let quantidade = '0';
    this.product.estoques.forEach(e => {
      if (cor == e['cor'] && est == e['estampa'] && tam == e['tamanho'] && fil == e['filial']) {
        quantidade = e['quantidade'];
      }
    });
    if (this.SITE.quantidadeMaximaGrade > 0 && quantidade >= this.SITE.quantidadeMaximaGrade) {
      quantidade = this.SITE.quantidadeMaximaGrade + '*';
    }
    return quantidade;
  }

  onSubmit() {
    if (this.pedido.tipo.precoDivergente) {
      for (let id in this.form.controls) {
        let _id = id.split('@');
        if (_id[0] == 'precoDiv') {
          continue;
        }
        let cor: number = parseInt(_id[0]);
        let est: number = parseInt(_id[1]);
        let tam: string = _id[2];
        let qtd: number = parseInt(this.form.get(id).value);
        let preco: number = 0;

        if (qtd > 0) {
          let precoMin: number = this.getPrecoSimple(cor, est, tam, this.SITE.precoDivergenteTabelaMin);
          let idPrecoDiv = 'precoDiv@' + cor + '@' + est + '@' + tam;
          let precoDiv = this.form.controls[idPrecoDiv].value.replace(".", "").replace(",", "");
          precoDiv = precoDiv.substr(0, precoDiv.length - 2) + "." + precoDiv.substr(precoDiv.length - 2, precoDiv.length);
          preco = Number(precoDiv);
          if (preco < precoMin) {
            Swal.fire({
              title: 'Preço divergente',
              text: "Este pedido será aprovado pelo administrador. Isso ocorre ao informar o preço abaixo do mínimo!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#ff9f15',
              confirmButtonText: 'Sim, irei aguardar aprovação!',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.value) {
                this.onSubmitOK();
              }
            }
            );
            return;
          }
        }
      }
      this.onSubmitOK();
    }
    else {
      this.onSubmitOK();
    }
  }

  onSubmitOK() {
    if (this.pedidoService.pedido.salvoCheckout) {
      Swal.fire({
        title: 'Confirma edição?',
        icon: 'warning',
        html: 'Pedido salvo anteriormente, será necessário reconfirmar o checkout',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: 'Editar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.onSubmitOKOK();
        }
      });
    }
    else {
      this.onSubmitOKOK();
    }
  }

  onSubmitOKOK() {
    if (!this.form.valid) {
      this.error = true;
      this.errorMsg = "Estoque insuficiente!";
      return;
    }
    if (this.pedidoService.loading)
      return;

    this.items = [];
    let pro: number = this.product.id;
    let proRef: string = this.product.referencia;
    let proDescr: string = this.product.descricao;
    let tabela: number = this.pedidoService.pedido.tabela.id;
    for (let id in this.form.controls) {
      let _id = id.split('@');
      if (_id[0] == 'precoDiv') {
        continue;
      }
      let cor: number = parseInt(_id[0]);
      let est: number = parseInt(_id[1]);
      let tam: string = _id[2];
      let cli: number = parseInt(_id[3]);
      let qtd: number = parseInt(this.form.controls[id].value);
      let preco: number = 0;
      let descontoPercent: number = 0;

      if (qtd > 0) {

        if (this.product.quantidadeMaximaDisponivelGrade != 0 && qtd > this.product.quantidadeMaximaDisponivelGrade) {
          this.error = true;
          this.errorMsg = "Máximo Disponivel para venda " + this.product.quantidadeMaximaDisponivelGrade + "!";
          return;
        }

        console.log('multiplo:', qtd % this.multiplo);

        if ((qtd % this.multiplo) > 0) {
          this.error = true;
          this.errorMsg = "Quantidade múltipla de (" + this.multiplo + ") incorreta!";
          return;
        }

        //console.log(id, cor, est, tam, cli, qtd);
        if (this.pedido.tipo.precoDivergente) {
          let idPrecoDiv = 'precoDiv@' + cor + '@' + est + '@' + tam;
          let precoDiv = this.form.controls[idPrecoDiv].value.replace(".", "").replace(",", "");
          precoDiv = precoDiv.substr(0, precoDiv.length - 2) + "." + precoDiv.substr(precoDiv.length - 2, precoDiv.length);
          preco = Number(precoDiv);
        }
        else {
          this.product.precos.forEach(p => {
            if (p['cor'] == cor && p['estampa'] == est && p['tamanho'] == tam && p['tabela'] == tabela) {
              if (p['precoPromocional'] != 0) {
                preco = p['precoPromocional'];
              }
              else {
                preco = p['preco'];
              }
            }
          });
        }
        if (preco <= 0) {
          console.log('cor, est, tam, tabela: ', cor, est, tam, tabela, 'preco: ' + preco);
          this.error = true;
          this.errorMsg = "Preço incorreto!";
          return;
        }

        if (this.descontoPercent != null) {
          preco = preco * (1 -(Number(this.descontoPercent.toString().replace(',','.')) * 0.01)); 
          preco = Number((preco).toFixed(2))
          descontoPercent = Number(this.descontoPercent.toString().replace(',','.'));          
        }

        //{estado: "AC", ipi: null, alIcms: 17, alIcmsOrigem: 17, substIcms: 1.99}
        let temImposto = false;
        let uf = '*';
        let valorImposto: number = 0;
        let imposto = { estado: null, ipi: null, alIcms: null, alIcmsOrigem: null, substIcms: null, fcpSt: null };
        for (let y = 0; y < this.clientes.length; y++) {
          if (cli == this.clientes[y]['id'] && this.clientes[y]['cidade']) {
            uf = this.clientes[y]['cidade']['uf'];
            break;
          }
        }
        for (let x = 0; x < this.product.impostos.length; x++) {
          if (uf == this.product.impostos[x]['estado']) {
            temImposto = true;
            imposto = this.product.impostos[x];
            break;
          }
        }
        if (!temImposto) {
          uf = '*';
          for (let x = 0; x < this.product.impostos.length; x++) {
            if (uf == this.product.impostos[x]['estado']) {
              temImposto = true;
              imposto = this.product.impostos[x];
              break;
            }
          }
        }
        console.log('uf imposto', uf, imposto);
        let impostoCalc = { al_fcp_st: null, valor_fcp_st: null, base_ipi: null, al_ipi: null, base_sub: null, al_sub: null, valor_ipi: null, valor_sub: null, base_fcp_st: null };
        if (temImposto) {
          impostoCalc = this.productService.calculaImposto(preco, imposto.ipi, imposto.alIcms, imposto.substIcms, imposto.alIcmsOrigem, imposto.fcpSt);
          console.log('imposto', imposto, impostoCalc);
          valorImposto = this.productService.calculaValorImposto(preco, imposto.ipi, imposto.alIcms, imposto.substIcms, imposto.alIcmsOrigem, imposto.fcpSt);;
          console.log('valorImposto', valorImposto);
        }
        let ___total = preco + valorImposto;

        //Verificando codigo de barras se já existe
        let codigoBarras = null;
        this.pedidoService.pedido.itens.forEach(e => {
          if (e.cor == cor && e.estampa == est && e.tamanho == tam && e.cliente == cli && e.produto == pro) {
            codigoBarras = e.codigoBarras;
          }
        });

        let newItem: OrderItem = {
          cor: cor,
          corDescricao: '',
          estampa: est,
          tamanho: tam,
          cliente: cli,
          produto: pro,
          produtoReferencia: proRef,
          produtoDescricao: proDescr,
          quantidade: qtd,
          preco: preco,
          acrescimo: 0,
          acrescimoPercent: 0,
          desconto: 0,
          descontoPercent: descontoPercent,
          total: qtd * ___total,
          imp_estado: imposto.estado,
          imp_ipi: imposto.ipi,
          imp_alicms: imposto.alIcms,
          imp_alicmsorigem: imposto.alIcmsOrigem,
          imp_substicms: imposto.substIcms,
          imp_alfcpst: imposto.fcpSt,
          imp_baseipi: impostoCalc.base_ipi,
          imp_basesubst: impostoCalc.base_sub,
          imp_basefcpst: impostoCalc.base_fcp_st,
          imp_valoripi: impostoCalc.valor_ipi,
          imp_valorsubst: impostoCalc.valor_sub,
          imp_valorfcpst: impostoCalc.valor_fcp_st,
          imp_valorimposto: valorImposto > 0 ? valorImposto : null,
          codigoBarras: codigoBarras
        };

        // if (this.descontoPercent != null){
        //   newItem.descontoPercent = Number(this.descontoPercent.toString().replace(',','.'));          
        //   let desconto_ = this.descontoPercent * 0.01 * preco * qtd;
        //   newItem.desconto =  Number(desconto_.toFixed(2));
        //   newItem.total -= newItem.desconto;
        // }

        console.log('newItem', newItem, ___total, newItem.total);
        this.items.push(newItem);
      }
    }
    if (!this.items.length) {
      let testPro = false;
      this.pedido.itens.forEach(i => {
        if (i.produto == pro) {
          testPro = true;
        }
      });
      if (!testPro) {
        this.error = true;
        this.errorMsg = "Informe a quantidade da grade!";
        return;
      }
    }
    //console.log('items', this.items);
    this.formQuantidadeDigitada = {};
    this.items.forEach(i => {
      let id = this.productService.getIdQtd(i.cor, i.estampa, i.tamanho);
      if (this.formQuantidadeDigitada[id]) {
        this.formQuantidadeDigitada[id] += i.quantidade;
        //console.log('@', i.quantidade);
      }
      else {
        //console.log('#', i.quantidade);
        this.formQuantidadeDigitada[id] = i.quantidade;
      }
    });
    //console.log('quantidade', this.formQuantidade);
    //console.log('quantidade digitada', this.formQuantidadeDigitada);
    if (this.pedido.tipo.validaEstoque) {
      for (let id in this.formQuantidade) {
        if (this.formQuantidadeDigitada[id] > this.formQuantidade[id]) {
          this.error = true;
          this.errorMsg = "Quantidade excedida! Verifique o formulário corretamente!";
          return;
        }
      }
    }
    //console.log('enviando itens', this.items);
    this.pedidoService.setCartItems(pro, this.items, function (resp) {
      Swal.fire('Produto', 'Salvo com sucesso!', 'success');
      this.form.markAsPristine();
      this.pedido = resp;
      this.pedidoService.select(resp);
    }.bind(this),
      function (error) {
        let obj = JSON.parse(error._body);
        if (obj && obj.msg) {
          this.error = true;
          this.errorMsg = obj.msg;
        }
      }.bind(this));
  }

  limparGrade() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValue('');
      this.form.controls[key].markAsUntouched();
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.dirty) {
      $('#confirmModal').modal('show');
      return this.canDeactivateObs.asObservable();
    }
    else {
      return true;
    }
  }

  changeClientColorPhoto(e) {
    switch (this.modoVisualizacaoPageProduto) {
      case 1:
        let cor = $(e.target).data('value');
        if (!cor)
          return;
    
        for (var i = 0; i < this.imagens.length; i++) {
          if (cor == this.imagens[i].cor) {
            this.corSelecionada = this.imagens[i];
            break;
          }
        }        
        break;
      case 2:
        let cliente = $(e.target).data('value');
        if (!cliente)
          return;
    
        this.clienteSelecionado = this.clientes.filter((i) => { return i.id == cliente; })[0];

        break;  
    }
  }

  init() {
    let newClass = '';
    let _htmlImagens = '';
    switch (this.modoVisualizacaoPageProduto) {
      case 1:
        if (this.imagens) {
          for (var i = 0; i < this.imagens.length; i++) {
            if (!this.SITE.mostraCorProdutoIndisponivel) {
              newClass = this.imagens[i].estoque ? '' : 'nostock';
            }
            _htmlImagens += `
            <div>
            <div class="miniatura ${newClass}" data-value="${this.imagens[i].cor}">
                <a href="javascript:void(0);" data-value="${this.imagens[i].cor}">
                    <img src="${this.imagens[i].src.value}" class="img-fluid" data-value="${this.imagens[i].cor}"/>
                    <div class="text-center" style="font-size: 0.6rem;" data-value="${this.imagens[i].cor}">${this.imagens[i].descricao}</div>
                </a>
            </div>
            </div>`;
          }
        }
      break;

      case 2:
        if (this.tamanhos) {
          _htmlImagens += `
          <div>
          <div class="miniatura2 ${newClass}>
              <div class="text-center"><b>Cliente</b></div>
          </a>
      </div>`;

          for (var i = 0; i < this.clientes.length; i++) {
            // if (!this.SITE.mostraCorProdutoIndisponivel) {
            //   newClass = this.imagens[i].estoque ? '' : 'nostock';
            // }
            _htmlImagens += `
            <div>
            <div class="miniatura2" ${newClass}>
                <a href="javascript:void(0);">
                    <div class="text-left" data-value="${this.clientes[i].id}">${this.clientes[i].cnpj}</div>
                </a>
            </div>
            </div>`;
          }
        }
          break;
    }        

    $('#listaImagens').html(_htmlImagens);
    if (this.imagens.length > 4) {
      $('#listaImagens').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        vertical: true,
        infinite: false,
        dots: true,
        autoplay: false,
        arrows: true
      });
    }

    
  }

  ngOnDestroy() {
    if ($('.ch-popover.ch-zoom'))
      $('.ch-popover.ch-zoom').remove();
  }

}