import { CreditCard, Payment, PaymentReturn as PaymentReturn } from '../../entities/creditCard'
import { Component, OnInit, Input } from '@angular/core';
import { IpService } from 'src/app/services/ip.service';
import { CreditCardService } from 'src/app/services/credit-card-service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})

export class CreditCardComponent implements OnInit {
  @Input() pedido: number;
  @Input() currentFormaPagamento;
  creditCard: CreditCard;
  payment: Payment;
  paymentReturn: PaymentReturn;
  processando: boolean = false;
  ipAddress : string;

   constructor(public ipService:IpService, public creditCardService: CreditCardService) {
   }

  ngOnInit(): void {
    this.GetCreditCardInstance();
    this.getIP();
    this.ConsultaPedido();
  }

  get getFilePaymentName(){
    return `Payment_Order${this.pedido}.json`;
  }

  get getFilePaymentReturnName(){
    return `PaymentReturn_Order${this.pedido}.json`;
  }

  get meses() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }

  get anos() {
    return [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
  }

ConsultaPedido(){
  return;

  if(this.creditCard)
  {
    this.paymentReturn = new PaymentReturn();

    this.creditCardService.consultaPedido(this.pedido,
      function(response){

        if (response && response.resultado && response.resultado.return_message){
            this.paymentReturn.status = response.resultado.return_message;
            PaymentReturn.setSucess(this.paymentReturn)
            this.processando = false;
        }

      }.bind(this))
  }
}

  Save() {
    this.processando = true;

    this.paymentReturn = new PaymentReturn();

    if(this.creditCard)
    {
      this.payment = this.ToPayment(this.creditCard);
      localStorage.setItem(this.getFilePaymentName, JSON.stringify(this.payment));
      this.creditCardService.enviaPedido(this.payment,
        function(response){
          this.Sleep();
          this.paymentReturn = <PaymentReturn>response;
          this.paymentReturn.status = "Operation Successful";
          PaymentReturn.setSucess(this.paymentReturn);
          this.processando = false;
        }.bind(this))
    }

    // this.Read();
  }

  GetCreditCardInstance(){
    let content = localStorage.getItem(this.getFilePaymentName);

    if (content){
      this.payment = <Payment>JSON.parse(content);
      this.creditCard = this.ToCreditCard(this.payment);
    }
    else{
      this.creditCard = new CreditCard();
      this.creditCard.pedido = this.pedido;
    }
  }

  async Read(){
      let content = localStorage.getItem(this.getFilePaymentReturnName);
      if (content)
        this.paymentReturn = <PaymentReturn>JSON.parse(content);

      if (this.paymentReturn.sucess != undefined){
        this.processando = false;
      }

      this.processando = true;

      await this.Sleep()

      this.Read();
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async Sleep()
  {
    console.log("Beforep: " + new Date().toString());
    await this.delay(2000);
    // console.log("Afterp:  " + new Date().toString());
  }

	public ToCreditCard(payment: Payment): CreditCard {
		let creditCard = new CreditCard()

		creditCard.pedido = payment.merchantOrderId;
		creditCard.nome = payment.nomeNoCartao;
		creditCard.numero= payment.numeroCartao;
		creditCard.validadeMes = Number(payment.dataExpiracao.substring(0, 2));
		creditCard.validadeAno =  Number(payment.dataExpiracao.substring(3, 7));
		creditCard.codigoSeguranca = payment.cvv;

		return creditCard;
	  }

	public ToPayment(creditCard: CreditCard) : Payment
	{
		let payment = new Payment()

    payment.parcelas = this.currentFormaPagamento ? this.currentFormaPagamento.descricao.split("/").length + 1 : 1;
		payment.merchantOrderId = creditCard.pedido;
		payment.nomeNoCartao = creditCard.nome;
		payment.numeroCartao = creditCard.numero;
		payment.dataExpiracao = `${creditCard.validadeMes.toString().padStart(2, '0') }/${creditCard.validadeAno}`;
		payment.cvv = creditCard.codigoSeguranca;
    payment.ipAdress = this.ipAddress;

		return payment;
	}

  HabilitarBotaoAutorizar(){
    // return true;

    if (!this.creditCard)
      return false;

    if (!this.creditCard.numero1 || this.creditCard.numero1.length != 4)
      return false;

    if (!this.creditCard.numero2 || this.creditCard.numero2.length != 4)
      return false;

    if (!this.creditCard.numero3 || this.creditCard.numero3.length != 4)
      return false;

    if (!this.creditCard.numero4 || this.creditCard.numero4.length != 4)
      return false;

    if (!this.creditCard.codigoSeguranca)
      return false;

    if (!this.creditCard.validadeAno)
      return false;

    if (!this.creditCard.validadeMes)
      return false;

    if (!this.creditCard.nome)
      return false;

    return !this.processando && (this.paymentReturn == undefined || this.paymentReturn.sucess == undefined || this.paymentReturn.sucess == false);
  }

  HabilitarMensagemAutorizando(){
    return this.processando;
  }

  HabilitarMensagemRetorno(){
    return this.paymentReturn && this.paymentReturn.sucess != undefined && this.paymentReturn.sucess != null
  }

  HabilitarEdicaoCampos(){
    return !this.processando && ((this.paymentReturn == null || this.paymentReturn.sucess == undefined || this.paymentReturn.sucess == false));
  }

  getIP()
  {
    this.ipService.getIPAddress().subscribe((res:any)=>{ this.ipAddress = res.ip;});
  }

  change(){
    this.creditCard.numero1 = this.setNumero(this.creditCard.numero1)
    this.creditCard.numero2 = this.setNumero(this.creditCard.numero2)
    this.creditCard.numero3 = this.setNumero(this.creditCard.numero3)
    this.creditCard.numero4 = this.setNumero(this.creditCard.numero4)
    this.creditCard.numero = `${this.creditCard.numero1}${this.creditCard.numero2}${this.creditCard.numero3}${this.creditCard.numero4}`

  }

  setNumero(num) {
    num = num.replace(/[^0-9\\]+/g, '')

    if (num.length == 0)
      return num

    let len = 4;
    var numberWithZeroes = String(num);
    var counter = numberWithZeroes.length;

    while(counter < len) {
      numberWithZeroes = "0" + numberWithZeroes;
      counter++;
    }

    return numberWithZeroes.substring(0, 4);
  }
}
