import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Configuracoes } from '../entities/configuracoes';

@Injectable({
    providedIn: "root"
})
export class CnpjService extends HttpService {

    constructor(public router: Router,
        public http: Http,
        public toastService: ToastrService) {
        super(http, toastService);
    }

    getCnpjAPIAgilb2b(cnpj: string, callback) {
        cnpj = cnpj.replace('.', '').replace('.', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.replace('-', '');
        this.showLoading();
        let url = Configuracoes.API_FRETE + "/cnpjapi?cnpj=" + cnpj;
        this.http.get(url, this.getHttpOptionsCnpj())
            .pipe(map(resp => resp.json()))
            .subscribe(
                resp => {
                    console.log(resp);
                    callback(resp);
                    this.dimissLoading();
                },
                error => {
                    console.log(error);
                    this.dimissLoading();
                }
            );
    }

    getHttpOptionsCnpj() {
        var header = new Headers({
            "Content-Type": "application/json",
            "Authorization": "Bearer sfhaiv#O(DIFUuhouidhfouishfoi@)UHOUIh"
        });
        return new RequestOptions({
            headers: header
        });
    }

}