import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin } from 'rxjs';
import { Configuracoes } from '../entities/configuracoes';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DownloadImagesService {

  getRequests = [];
  data = [];
  loading: Boolean = false;
  toastLoadingId: number;

  constructor(private _http: HttpClient, private toastService: ToastrService) { }

  clear() {
    this.data = [];
  }

  append(data) {
    this.data = this.data.concat(data);
  }

  async download(fileName: string) {
    this.showLoading();
    const zip = new JSZip();
    console.log(this.data);

    for (let index = 0; index < this.data.length; index++) {
      const url = this.data[index];
      try {
        let blob = await this._http.get(url, { responseType: 'blob' }).toPromise();
        const name = url.replace(Configuracoes.API_IMAGENS, "");
        zip.file(`${name}`, blob);
      } catch (e) {
        console.log(e);
      }
    }

    zip
      .generateAsync({ type: 'blob' })
      .then(blob => {
        saveAs(blob, `${fileName}.zip`);
      });

    this.data = [];
    this.dimissLoading();
  }

  showLoading() {
    this.loading = true;
    this.toastLoadingId = this.toastService.show('<div class="spinner-grow text-primary" role="status"><span class="sr-only">Loading...</span></div>', '', {
      enableHtml: true
    }).toastId;
  }

  dimissLoading() {
    this.loading = false;
    this.toastService.clear(this.toastLoadingId);
  }
}