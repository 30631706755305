import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateBRA' })
export class DateBRAPipe implements PipeTransform {
  transform(d:string) {
    //2000-11-11
    if(d){
      let t = d.split('-');
      return t[2] + '/' + t[1] + '/' + t[0];
    }
    return '';
  }
}
