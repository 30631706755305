import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app.routes';
import { SliderPrincipalComponent } from './components/slider-principal/slider-principal.component';
import { SliderSuperiorComponent } from './components/slider-superior/slider-superior.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotfoundComponent } from './pages/page-notfound/page-notfound.component';
import { SliderImagemComponent } from './components/slider-imagem/slider-imagem.component';
import { SliderProdutoComponent } from './components/slider-produto/slider-produto.component';
import { FilterByKeyPipe } from './pipes/filterByKey.pipe';
import { DateBRAPipe } from './pipes/dateBRA.pipe';
import { VarDirective } from './directive/ng-var.directive';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoginGuard } from './services/loginguard';
import { InitProvider } from './services/init-provider';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { SimpleHtmlModule } from './components/simple-html/simple-html.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NovoClienteComponent } from './components/novo-cliente/novo-cliente.component';
import { NewsLetterComponent } from './components/news-letter/news-letter.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialMarketingModule } from './pages/material-marketing/material-marketing.module';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePt, 'pt-BR');

export function filtroProviderFactory(provider:InitProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SliderPrincipalComponent,
    SliderSuperiorComponent,
    HeaderComponent,
    FooterComponent,
    PageNotfoundComponent,
    SliderImagemComponent,
    SliderProdutoComponent,
    FilterByKeyPipe,
    DateBRAPipe,
    VarDirective,
    NovoClienteComponent,
    NewsLetterComponent,
    CreditCardComponent,
  ],
  imports: [
    MaterialMarketingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    SimpleHtmlModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SweetAlert2Module.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    LoginGuard,
    InitProvider,
    { provide: APP_INITIALIZER, useFactory: filtroProviderFactory, deps: [InitProvider], multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
